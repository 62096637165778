/* eslint-disable consistent-return */

import { useEffect, RefObject, useRef, MutableRefObject } from 'react';

type Event = MouseEvent | TouchEvent;

export const useClickAway = <T extends HTMLElement = HTMLElement>(
  handler: (event: Event) => void,
  exceptionRefs?: RefObject<T>[],
  attached = true
): MutableRefObject<null> => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!attached) {
      return;
    }

    const listener = (event: Event) => {
      const el = ref?.current;

      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      const exceptionElements = exceptionRefs?.length
        ? exceptionRefs.map((exceptionRef) => exceptionRef.current)
        : null;

      const isException = exceptionElements?.some((exceptionEl) =>
        exceptionEl?.contains(event?.target as Node)
      );

      if (isException) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);

  return ref;
};
