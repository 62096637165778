import { useSnackbar } from 'notistack';

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  return {
    showSuccessToast: (message: string) =>
      enqueueSnackbar(message, { variant: 'success' }),

    showErrorToast: (message = 'Something went wrong') =>
      enqueueSnackbar(message, { variant: 'error' }),

    showInfoToast: (message: string) =>
      enqueueSnackbar(message, { variant: 'info' }),

    showWarnToast: (message: string) =>
      enqueueSnackbar(message, { variant: 'warning' }),
  };
};
